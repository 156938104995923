.text-block {
  height: 100%;
  margin-bottom: 10px;

  .inner {
    padding: 20px;
    background: $grayLight;
    height: 100%;

    p {
      margin-bottom: 0;
    }
  }
}

.plate--element__text_block {
  height: 100%;
  margin-bottom: 30px;
}

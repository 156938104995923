.references-overview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  &__item {
    position: relative;
    overflow: hidden;

    .image-wrapper {
      height: 200px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        filter: grayscale(100%);
        transition: ease 200ms all;
      }

      &::after {
        content: '';
        background: $black;
        opacity: 0.2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    h3 {
      position: absolute;
      bottom: 15px;
      left: 15px;
      color: $white;
      z-index: 1;
      margin: 0;
      transition: ease 200ms all;
    }

    &:hover {
      h3 {
        transform: translateY(-3px);
      }

      img {
        filter: none;
      }
    }
  }

  @media (max-width: $media-tablet) {
    grid-template-columns: 1fr;
  }
}

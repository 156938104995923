.header {
  &__top {
    background: $grayLight;

    body.stick-menu & {
      margin-bottom: 70px;
    }

    .top-inner {
      display: flex;
      height: 50px;

      .top-items {
        margin: auto;
        margin-right: 0;
        display: flex;

        li {
          list-style: none;

          a {
            color: #878786;
            padding: 0 15px;
            border-right: 1px solid #dedede;
            font-size: 15px;

            &::before {
              content: var(--icon);
              font-family: $font-family-fontawesome;
              color: #878786;
              margin-right: 10px;
              font-weight: 900;
            }
          }

          &:last-child {
            a {
              padding-right: 0;
              border: none;
            }
          }
        }
      }
    }
  }

  &__main {
    width: 100%;
    background: $white;
    box-shadow: 0px 6px 7px -4px #00000054;
    top: 0;

    body.stick-menu & {
      position: fixed;
      z-index: 100;

      .header__logo {
        width: 150px;
      }
    }
  }

  &__inner {
    display: flex;
    height: 100px;
  }

  &__logo {
    margin: auto;
    margin-left: 0;

    img {
      width: 265px;
    }
  }

  &__menu {
    display: flex;
    margin: auto auto auto 0;

    li {
      list-style: none;
      display: flex;
      position: relative;
      padding: 10px 0;

      &.active {
        a {
          color: $blue;
          font-weight: bold;
        }
      }

      a {
        text-decoration: none;
        color: $body-color;
        padding: 0 25px;
        margin: auto;

        &.button {
          @extend .button;
          @extend .button--orange;

          padding: 10px 20px !important;
          margin-left: 25px;
        }

        &:last-child {
          padding-right: 0;
        }

        &:hover {
          color: $blue;
          font-weight: bold;
        }
      }

      &.has-sub {
        > ul.sub {
          position: absolute;
          top: 46px;
          background: #fff;
          padding: 20px 35px 0;
          border-bottom: 9px solid $blue;
          min-width: 240px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          z-index: 100;

          li {
            padding: 0;
            padding-bottom: 20px;

            &.active {
              a {
                color: $blue;
                font-weight: bold;
              }
            }

            a {
              padding: 0;
              margin: 0;
              font-weight: normal;
              color: $black;

              &:hover {
                color: $blue;
                font-weight: bold;
              }
            }

            &::before {
              content: none;
            }
          }
          display: none;
        }

        &:hover {
          > a {
            color: $blue;
            font-weight: bold;
          }

          > ul.sub {
            display: block;
          }

          &::after {
            color: $blue;
            transform: rotate(180deg);
          }
        }

        &::after {
          content: '\f107';
          font-size: 20px;
          font-family: $font-family-fontawesome;
          color: $black;
          position: absolute;
          right: 5px;
          top: 10px;
          transition: ease 200ms all;
        }
      }
    }
  }

  &__cta {
    margin: auto 0 auto auto;
  }

  @media (max-width: $media-tablet) {
    &__top {
      display: none;
    }

    &__menu {
      display: none;
    }

    &__inner {
      display: flex;
      height: 70px;
    }

    &__logo {
      img {
        width: 160px;
      }
    }

    &__cta {
      display: none;
    }

    &__toggle {
      margin: auto;
      margin-right: 0;
      z-index: 100;
      cursor: pointer;

      .bars {
        .bar {
          height: 4px;
          width: 25px;
          margin-bottom: 5px;
          background-color: $body-color;
          transition: all 350ms ease;
        }

        body.menu-open & {
          .bar:nth-child(1) {
            transform: rotate(45deg);
          }

          .bar:nth-child(2) {
            opacity: 0;
          }

          .bar:nth-child(3) {
            transform: rotate(-45deg);
            margin-top: -18px;
          }
        }
      }
    }
  }
}

.mobile-menu {
  display: none;
}

@media (max-width: $media-tablet) {
  .mobile-menu {
    display: block;
    background: $grayLight;
    position: fixed;
    top: 70px;
    height: 100vh;
    width: 80vw;
    right: -80vw;
    padding: 35px 0 140px 0;
    transition: transform 0.5s ease;
    overflow-y: scroll;
    scroll-behavior: smooth;
    z-index: 90;

    body.menu-open & {
      transform: translateX(-80vw);
    }

    body.menu-open {
      .wrapper {
        height: 100vh;
      }
    }

    .mobile-header {
      display: block;
      padding-left: 25px;
      font-weight: bold;
    }

    li {
      display: block;
      width: 100%;
      border-bottom: 1px solid darken($grayLight, 10%);
      padding: 10px 0;
      list-style: none;
      display: flex;

      a {
        text-decoration: none;
        color: $body-color;
        padding: 0 25px;

        &.button {
          color: #fff;
          padding: 10px 20px !important;
          margin-left: 25px;
        }

        &:last-child {
          padding-right: 0;
        }

        &:hover {
          color: $blue;
        }
      }

      > ul {
        display: none;
      }

      &.active {
        a {
          color: $blue;
        }
      }

      .button {
        padding: 10px !important;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

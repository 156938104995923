.cta {
  background: $blue;
  padding: 15px 30px;
  margin: 25px 0;
  display: flex;
  justify-content: space-between;

  span {
    margin: auto auto auto 0;
    font-size: 25px;
    color: #fff;
  }

  a {
    margin: auto 0 auto auto;
  }

  @media (max-width: $media-tablet) {
    display: block;

    span {
      display: block;
      margin-bottom: 15px;
    }
  }
}

.quote {
  hr {
    margin: 50px 0;
    border-color: $grayLight;
    opacity: 0.3;
  }

  .tab {
    display: flex;
    flex-wrap: wrap;

    label {
      width: calc(100% - 20px);
      margin-bottom: 20px;

      &.half {
        width: calc(50% - 20px);
        margin-right: 20px;
      }
    }

    &--1 {
      //
    }

    &--2 {
      display: none;
    }

    &--3 {
      display: none;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;

    span {
      background: $blue;
      padding: 10px 20px;
      color: #fff;
      cursor: pointer;

      &.prev {
        margin: auto;
        margin-left: 0;
        display: none;
      }

      &.next {
        margin: auto;
        margin-right: 0;
      }
    }

    .submit {
      display: none;
    }
  }

  ul {
    margin-bottom: 20px;
    width: 100%;

    li {
      padding: 0;

      input {
        margin-right: 10px;
      }

      label {
        margin: 0;
        font-weight: normal;
      }

      &::before {
        content: none;
      }
    }
  }

  label {
    display: block;
  }

  @media (max-width: $media-tablet) {
    .section {
      display: block;

      label {
        width: 100%;

        &.half {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}

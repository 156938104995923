.submenu {
  padding: 0;
  margin: 0;
  margin-top: 15px;

  li {
    padding: 0;

    a {
      border-bottom: 1px solid #ccc;
      display: block;
      padding: 5px;
      color: $body-color;

      &.active {
        color: $blue;
      }
    }

    > .children {
      padding: 0;
      margin: 0;

      a {
        padding-left: 10px;
      }
    }

    &::before {
      content: none;
    }
  }
}

.button {
  border-radius: 3px;
  padding: 10px 20px;
  transition: ease-in 200ms all;
  display: inline-block;
  content: var(--icon);

  &::before {
    content: var(--icon);
    font-family: $font-family-fontawesome;
    color: $white;
    margin-right: 10px;
  }

  &--orange {
    background: $orange;
    color: $white;

    &:hover {
      color: $white;
      background: darken($orange, 5%);
    }
  }
}

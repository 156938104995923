$fontpath: '/theme/assets/fonts';

/* ubuntu-300 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300.svg#Ubuntu')
      format('svg'); /* Legacy iOS */
}
/* ubuntu-regular - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-300italic - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  src: url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-300italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-italic - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-italic.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-500 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500.svg#Ubuntu')
      format('svg'); /* Legacy iOS */
}
/* ubuntu-700 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700.svg#Ubuntu')
      format('svg'); /* Legacy iOS */
}
/* ubuntu-500italic - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500italic.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-500italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700italic - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700italic.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$fontpath}/ubuntu-v15-latin/ubuntu-v15-latin-700italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}

/* pt-sans-regular - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-regular.svg#PTSans') format('svg'); /* Legacy iOS */
}
/* pt-sans-italic - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  src: url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-italic.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-italic.svg#PTSans') format('svg'); /* Legacy iOS */
}
/* pt-sans-700 - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700.svg#PTSans') format('svg'); /* Legacy iOS */
}
/* pt-sans-700italic - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  src: url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700italic.woff') format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$fontpath}/pt-sans-v12-latin/pt-sans-v12-latin-700italic.svg#PTSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Fontawesome';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fontpath}/font-awesome/fa-regular-400.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/font-awesome/fa-regular-400.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/font-awesome/fa-regular-400.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/font-awesome/fa-regular-400.woff') format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/font-awesome/fa-regular-400.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('#{$fontpath}/font-awesome/fa-regular-400.svg#PTSans')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Fontawesome';
  font-style: normal;
  font-weight: 900;
  src: url('#{$fontpath}/font-awesome/fa-solid-900.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$fontpath}/font-awesome/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$fontpath}/font-awesome/fa-solid-900.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$fontpath}/font-awesome/fa-solid-900.woff') format('woff'),
    /* Modern Browsers */ url('#{$fontpath}/font-awesome/fa-solid-900.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('#{$fontpath}/font-awesome/fa-solid-900.svg#PTSans')
      format('svg'); /* Legacy iOS */
}

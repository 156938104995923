.icon_text {
  text-align: center;

  .icon {
    text-align: center;
    margin-bottom: 20px;
    display: block;

    img {
      width: 80px;
    }
  }
}

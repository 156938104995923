* {
  box-sizing: border-box;
}

.wrapper {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

:target:before {
  content: '';
  display: block;
  height: 70px;
  margin: -70px 0 0;
}

body {
  font-family: $font-family-base;
  color: $body-color;
  line-height: 27px;
  font-size: 17px;
}

h1 {
  font-size: 42px;
  line-height: 48px;
  margin: 0;
  font-family: $font-family-ubuntu;
  color: $gray;
}

h2 {
  font-size: 31px;
  line-height: 38px;
  font-family: $font-family-ubuntu;
  color: $gray;
}

h3 {
  font-size: 24px;
  line-height: 33px;
  font-family: $font-family-ubuntu;
  color: $gray;
  margin: 0;
}

h4 {
  font-size: 21px;
  line-height: 29px;
  font-family: $font-family-ubuntu;
  color: $gray;
  margin: 0;
}

a {
  color: $blue;
  text-decoration: none;
}

img {
  max-width: 100%;
}

ul {
  // padding-left: 0;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    padding-left: 30px;
    //   &::before {
    //     content: '\2022';
    //     color: $white;
    //     font-weight: bold;
    //     display: inline-block;
    //     width: 1em;
    //     margin-left: -1em;
    //   }
    &::before {
      font-family: $font-family-fontawesome;
      content: '\f138';
      color: #268ec6;
      font-size: 14px;
      display: inline-block;
      font-weight: 900;
      position: absolute;
      left: 0;
    }
  }
}

.row {
  display: flex;

  h1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .plate--page-content-wrapper {
    margin-left: -15px;
  }

  .col-left {
    width: 25%;
  }

  .col-right {
    margin-left: 30px;
    width: calc(75% - 30px);
  }
}

@media (max-width: $media-tablet) {
  .wrapper {
    padding-top: 70px;
  }

  .row {
    flex-direction: column;

    .col-left {
      width: 100%;
    }

    .col-right {
      width: 100%;
      margin: 0;
    }
  }
}

.references-show {
  margin-bottom: 50px;

  .reference-top {
    display: flex;

    .image {
      img {
        height: 300px;
        max-width: 600px;
        object-fit: cover;
      }
    }

    .specs {
      padding-left: 35px;

      li {
        display: flex;
        margin-bottom: 50px;
        padding: 0;

        &::before {
          content: none;
        }

        &:last-child {
          margin-bottom: 0;
        }

        img {
          width: 28px;
          height: 28px;
          margin-right: 20px;
        }

        span {
          margin: auto;
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: $media-tablet) {
    .reference-top {
      display: block;

      .image {
        img {
          width: 100%;
        }
      }

      .specs {
        padding: 0;
      }
    }
  }
}

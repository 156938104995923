.form {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__width {
    &--50 {
      width: calc(50% - 20px);
      margin-right: 20px;
    }

    &--100 {
      width: calc(100% - 20px);
      margin-right: 20px;
    }
  }
}

label {
  font-weight: bold;
}

input[type='text'],
input[type='email'] {
  width: 100%;
  border: none;
  height: 46px;
  background: $grayLight;
  text-indent: 10px;
}

textarea {
  width: 100%;
  border: none;
  min-height: 200px;
  background: $grayLight;
  text-indent: 10px;
}

input[type='submit'] {
  background: $blue;
  padding: 10px 20px;
  color: #fff;
  border: none;
  font-family: $font-family-base;
  cursor: pointer;
}

// input[type='file'] {
//   width: 0.1px;
//   height: 0.1px;
//   opacity: 0;
//   overflow: hidden;
//   position: absolute;
//   z-index: -1;
// }

// input[type='file'] + label {
//   font-size: 1.25em;
//   font-weight: 700;
//   color: white;
//   background-color: black;
//   display: inline-block;
// }

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  // font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 5px;
  top: 5px;
  width: 15px;
  height: 15px;
  background: $blue;
}

@media (max-width: $media-tablet) {
  .form {
    &__width {
      &--50 {
        width: 100%;
        margin-right: 0px;
      }

      &--100 {
        width: 100%;
        margin-right: 0px;
      }
    }
  }
}

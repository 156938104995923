.advantages {
  margin-top: 20px;
  padding: 15px;
  background: $grayLight;

  ul {
    li {
      margin-left: 5px;
      position: relative;

      &:before {
        content: '\f00c';
        font-family: $font-family-fontawesome;
        color: $green;
        left: -5px;
        position: absolute;
      }
    }
  }
}

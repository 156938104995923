.banner {
  &--default {
    height: 150px;

    img {
      object-fit: cover;
      width: 100%;
      height: 150px;
    }
  }

  &--front {
    height: 350px;

    img {
      object-fit: cover;
      width: 100%;
      height: 350px;
    }
  }

  @media (max-width: $media-tablet) {
    &--front {
      height: 200px;

      img {
        height: 200px;
      }
    }
  }
}

.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  &__image {
    img {
      height: 200px;
      object-fit: contain;
      filter: grayscale(100%);
      transition: 200ms all ease;

      &:hover {
        filter: none;
      }
    }
  }

  @media (max-width: $media-tablet) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    &__image {
      img {
        height: 120px;
      }
    }
  }
}

.footer {
  margin-top: 35px;

  &__top {
    background: $blue;
    padding: 35px 0;
  }

  &__bottom {
    background: $blueDark;
    padding: 50px 0;

    .footer-row {
      display: flex;
      justify-content: space-between;

      .social {
        margin: auto;
        margin-left: 0;

        img {
          width: 21px;
        }
      }

      .scroll {
        margin: auto;

        .scroll-inner {
          border: 1px solid $white;
          height: 45px;
          width: 45px;
          border-radius: 3px;
          display: flex;
          cursor: pointer;

          img {
            margin: 0 auto;
          }
        }
      }

      .links {
        display: flex;
        margin: auto 0;

        span {
          color: $white;
          font-size: 12px;
          margin-right: 5px;
        }

        li {
          list-style: none;

          a {
            padding: 0 5px;
            border-left: 1px solid #fff;
            font-size: 12px;
          }

          &:last-child {
            a {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  h2,
  h3 {
    color: $white;
    margin-bottom: 20px;
  }

  p {
    color: $white;
  }

  a {
    color: $white;
  }

  ul {
    li {
      &::before {
        content: '\2022';
        color: $white;
        font-weight: bold;
        display: inline-block;
        font-size: 20px;
      }

      a {
        color: $white;
      }
    }
  }

  @media (max-width: $media-tablet) {
    h2,
    h3 {
      margin-top: 20px;
    }

    &__bottom {
      .footer-row {
        display: block;
        text-align: center;
      }

      .scroll {
        .scroll-inner {
          margin: 15px auto;
        }
      }

      .links {
        justify-content: center;
      }
    }
  }
}

.breadcrumbs {
  background: $blueLight;
  padding: 10px 0;
  color: #878786;

  &__inner {
    display: flex;

    img {
      margin: auto 5px;
      height: 15px;
    }

    a {
      color: #878786;
      margin: 0 5px;
      font-size: 14px;
    }
  }
}
